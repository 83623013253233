import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Item: {
   background:'#f4fdff',
    borderRadius:'4px',
    border: '1px dashed #aaa',
    marginLeft:18,
   
  },
}));

export default useStyles;
